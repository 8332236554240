import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "creditCardMaskPipe" })
export class CreditCardMaskPipe implements PipeTransform {
  public transform(plainCreditCard: string | number): string {
    if (plainCreditCard) {
      return plainCreditCard.toString()
        .replace(/\s+/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim();
    }
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { IAmount } from "@departments/interfaces/payment-gateway/amount.interface";

@Pipe({
  name: "amountCurrency",
})
export class AmountCurrencyPipe implements PipeTransform {
  public transform(amount: IAmount): string {
    const formattedValue = amount.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return `${formattedValue} ${amount.currency}` || "-";
  }
}

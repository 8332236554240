import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { Observable, map } from "rxjs";

import { IClientsData } from "@features/departments/interfaces/clients/create-customer.interface";
import { PhysicalClientService } from "@departments/modules/card-on-boarding/front/clients/services/physical-client.service";
import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";
import { IResponse } from "@core/interfaces/response.interface";
import { ActionsEnum } from "@shared/enums/enums";
import { PHONE_NUMBER_PREFIX_UZB_PLUS } from "@shared/constants/phone-number-prefixes";
import { atLeastOneValidator } from "@shared/validators/atLeastOneField.validator";
import { IClientScan } from "@core/services/devices/scanner/interfaces/client-scan.interface";

@Component({
  selector: "app-clients-search",
  templateUrl: "./clients-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsSearchComponent
  extends UnsubscribeDirective
  implements OnInit
{
  @Input() public availableActionsWithClient: ActionsEnum[];

  @Output() public selectedClientId: EventEmitter<string> =
    new EventEmitter<string>();

  public clientData$: Observable<IClientsData>;

  public isPINFLReadonly = false;
  public isDocumentNumberReadonly = false;
  public isDocumentSerialReadonly = false;
  public isUsernameReadonly = false;
  public isScanButtonDisabled = false;

  public phonePrefix = PHONE_NUMBER_PREFIX_UZB_PLUS;
  public searchForm: FormGroup;
  public queryParams: Params;

  public constructor(
    private _formBuilder: FormBuilder,
    private _physicalClientService: PhysicalClientService,
    private _router: Router,
    private _activeRoute: ActivatedRoute
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initSearchForm();
    this.initDefaultQueryParams();
    this.fetchFromParams();
  }

  public fetchFromParams(): void {
    this.subscribeTo = this._activeRoute.queryParams.subscribe({
      next: (params: Params): void => {
        if (Object.keys(params).length === 0) {
          return;
        }

        this.searchForm.patchValue({
          username: params.username,
          pinfl: params.pinfl,
          documentSerial: params.documentSerial,
          documentNumber: params.documentNumber,
        });

        this.onSubmit();
      },
    });
  }

  public onSelectedClientId(clientId: string): void {
    this.selectedClientId.emit(clientId);
  }

  public resetSearching(): void {
    this.isScanButtonDisabled = false;
    this.clientData$ = null;
    this.setDefaultQueryParams();
    this.clearSearchForm();
    this.switchFieldsReadonlyProperty(false);
    this.navigateToClientSearch();
    this.selectedClientId.emit("");
  }

  public scanningStarted(): void {
    this.resetSearching();
    this.switchFieldsReadonlyProperty(true);
    this.isScanButtonDisabled = true;
  }

  public scanDocument(clientScan: IClientScan): void {
    if (clientScan) {
      this.searchForm.patchValue(clientScan);
      this.onSubmit();
    }
  }

  protected onSubmit(): void {
    this.selectedClientId.emit("");

    if (this.searchForm.invalid) return;

    for (const key in this.searchForm.value) {
      if (this.searchForm.value[key] !== "") {
        this.queryParams[key] = this.searchForm.value[key];
      } else {
        this.queryParams[key] = null;
      }
    }

    this.navigateToClientSearch();

    const requestBody: { data: object } = {
      data: this.searchForm.value,
    };

    this.clientData$ = this._physicalClientService
      .getCustomersList(requestBody)
      .pipe(
        map((data: IResponse<IClientsData>) => {
          return data.data;
        })
      );
  }

  private initSearchForm(): void {
    this.searchForm = this._formBuilder.group(
      {
        username: [
          "",
          {
            validators: [Validators.pattern(/^\d{9}$/)],
          },
        ],
        pinfl: [
          "",
          {
            validators: [Validators.pattern("[0-9]{14}")],
          },
        ],
        documentSerial: [
          "",
          {
            validators: [Validators.pattern("[A-Z]{2}")],
          },
        ],
        documentNumber: [
          "",
          {
            validators: [Validators.pattern("[0-9]{7}")],
          },
        ],
      },
      {
        validators: [atLeastOneValidator],
      }
    );
  }

  private initDefaultQueryParams(): void {
    this.queryParams = {
      username: null,
      pinfl: null,
      documentSerial: null,
      documentNumber: null,
    };
  }

  private setDefaultQueryParams(): void {
    this.queryParams = {
      username: null,
      pinfl: null,
      documentSerial: null,
      documentNumber: null,
    };
  }

  private navigateToClientSearch(): void {
    void this._router.navigate([], {
      relativeTo: this._activeRoute,
      queryParams: this.queryParams,
      queryParamsHandling: "merge",
    });
  }

  private clearSearchForm(): void {
    this.searchForm.controls.pinfl.patchValue("");
    this.searchForm.controls.username.patchValue("");
    this.searchForm.controls.documentSerial.patchValue("");
    this.searchForm.controls.documentNumber.patchValue("");
  }

  private switchFieldsReadonlyProperty(isReadonlyFlag: boolean): void {
    this.isPINFLReadonly = isReadonlyFlag;
    this.isDocumentNumberReadonly = isReadonlyFlag;
    this.isDocumentSerialReadonly = isReadonlyFlag;
    this.isUsernameReadonly = isReadonlyFlag;
  }
}

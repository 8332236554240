import { Injectable } from "@angular/core";

import moment from "moment";

import { CountryCodesEnum } from "@shared/enums/country-codes.enum";
import {
  IClientDocScan,
  IClientScan,
} from "@core/services/devices/scanner/interfaces/client-scan.interface";

@Injectable({
  providedIn: "root",
})
export class ScannerHelper {
  public static formatScannedDate(date: string): string {
    const currentYear = moment().local().year().toString().substring(2);
    const clientDateOfBirth = date;
    const day = clientDateOfBirth.substring(0, 2);
    const month = moment()
      .month(+clientDateOfBirth.substring(3, 5) - 1)
      .format("MM");
    const year =
      +clientDateOfBirth.substring(8) > +currentYear
        ? `19${clientDateOfBirth.substring(8)}`
        : `20${clientDateOfBirth.substring(8)}`;

    return `${year}.${month}.${day}`;
  }

  public static convertClientData(clientDocScan: IClientDocScan): IClientScan {
    const clientScan: IClientScan = {};

    if (clientDocScan.dateOfBirth) {
      clientScan.dob = moment(
        ScannerHelper.formatScannedDate(clientDocScan.dateOfBirth)
      );
    }

    if (clientDocScan.documentSerialNumber) {
      clientScan.documentSerial = this.extractDocSerial(
        clientDocScan.documentSerialNumber
      );
      clientScan.documentNumber = this.extractDocNumber(
        clientDocScan.documentSerialNumber
      );
    }

    if (this.validatePINFL(clientDocScan)) {
      clientScan.pinfl = clientDocScan.pinfl;
    }

    return clientScan;
  }

  public static extractDocSerial(docSerialNumber: string): string {
    return docSerialNumber.substring(0, 2);
  }

  public static extractDocNumber(docSerialNumber: string): string {
    return docSerialNumber.substring(2);
  }

  public static validatePINFL(clientDocScan: IClientDocScan): boolean {
    return (
      clientDocScan.countryCode &&
      clientDocScan.pinfl &&
      clientDocScan.countryCode === CountryCodesEnum.Uzbekistan
    );
  }
}

import { ChangeDetectorRef, Component, NgZone, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { filter, first, interval, Observable } from "rxjs";

import { TranslocoService } from "@ngneat/transloco";

import { MouseStateService } from "@core/state/mouse-state.service";
import { AuthService } from "@features/auth/auth.service";
import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";
import { ALLOWABLE_INACTIVITY } from "@shared/constants/allowable-inactivity";
import { LANGUAGE_KEY } from "@shared/constants/storage-keys";
import { PathTrackerService } from "@core/services/common/path-tracker/path-tracker.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends UnsubscribeDirective implements OnInit {
  private isAuthenticated$: Observable<boolean> =
    this._authService.getIsAuthenticated();

  public constructor(
    private _mouseStateService: MouseStateService,
    private _authService: AuthService,
    private _router: Router,
    private _translocoService: TranslocoService,
    private _pathTrackerService: PathTrackerService,
    private _ngZone: NgZone,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.checkIsAuth();
    this.defineLang();
    this.trackPaths();
  }

  private trackPaths(): void {
    this.subscribeTo = this._router.events
      .pipe(filter((event): boolean => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd): void => {
        this._pathTrackerService.setPath(event.urlAfterRedirects);
      });
  }

  private defineLang(): void {
    const currentLanguage = localStorage.getItem(LANGUAGE_KEY);

    if (currentLanguage) {
      this._translocoService.setActiveLang(currentLanguage);
      return;
    }

    const defaultLanguage = this._translocoService.getDefaultLang();
    this._translocoService.setActiveLang(defaultLanguage);
    localStorage.setItem(LANGUAGE_KEY, defaultLanguage);
  }

  private checkIsAuth(): void {
    this.isAuthenticated$.subscribe({
      next: (isAuth: boolean) => {
        if (isAuth) {
          this.trackActivity();

          return;
        }

        this.unsubscribeFromAll();
      },
    });
  }

  private trackActivity(): void {
    this.trackMouseMovement();
    this.setMouseInactivity();
    this.trackMouseInactiveTime();
  }

  private trackMouseMovement(): void {
    this.subscribeTo = this._mouseStateService.getMouseMovement.subscribe(
      () => {
        this._mouseStateService.setDefaultMouseInactiveTime();
      }
    );
  }

  private setMouseInactivity(): void {
    this._ngZone.runOutsideAngular((): void => {
      this.subscribeTo = interval(1000).subscribe((): void => {
        this._mouseStateService.setMouseInactiveTime();
      });
    });
  }

  private trackMouseInactiveTime(): void {
    this.subscribeTo = this._mouseStateService.getMouseInactiveTime.subscribe(
      (inactiveTime: number): void => {
        if (ALLOWABLE_INACTIVITY - inactiveTime < 60) {
          if (!this._cdr["destroyed"]) {
            this._ngZone.run(() => this._cdr.detectChanges());
          }
        }

        if (
          inactiveTime === ALLOWABLE_INACTIVITY &&
          this._authService.accessToken
        ) {
          this._authService.setIsSignedOutBecauseInactive("true");
          this._authService
            .signOut()
            .pipe(first())
            .subscribe(() => location.reload());
        }
      }
    );
  }
}

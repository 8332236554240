<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  *ngIf="isNavigationVisible"
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation"
  [opened]="false"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex justify-center items-center h-20 p-6 pb-0">
      <a [routerLink]="['/services']">
        <!-- Light version -->
        <img
          class="dark:hidden w-30"
          ngSrc="assets/images/logo/logo.svg"
          alt="Apex Bank Logo"
          height="108"
          width="620"
        />

        <!-- Dark version -->
        <img
          class="hidden dark:flex w-30"
          ngSrc="assets/images/logo/logo.svg"
          alt="Apex Bank Logo"
          height="108"
          width="620"
        />
      </a>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <div *ngIf="countdown" class="text-primary font-bold text-2xl">
      <p>
        {{
          ALLOWABLE_INACTIVITY - countdown > 9
            ? "00:" + (ALLOWABLE_INACTIVITY - countdown)
            : "00:0" + (ALLOWABLE_INACTIVITY - countdown)
        }}
      </p>
    </div>
    <!-- Logo -->
    <div
      *ngIf="!isNavigationVisible"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <a [routerLink]="['']">
        <img
          class="dark:hidden w-40"
          ngSrc="assets/images/logo/logo-black.svg"
          alt="Apex Bank Logo"
          height="108"
          width="620"
        />
      </a>
    </div>
    <!-- Navigation toggle button -->
    <button
      *ngIf="isNavigationVisible"
      mat-icon-button
      (click)="toggleNavigation('mainNavigation')"
    >
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <languages></languages>
      <user></user>
    </div>
  </div>

  <app-breadcrumbs />

  <div class="flex flex-col container p-4 w-full">
    <router-outlet></router-outlet>
  </div>
</div>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";

import { TranslocoModule } from "@ngneat/transloco";

import { CreditCardMaskPipe } from "@shared/pipes/credit-card-mask.pipe";
import { PaymentTypeMaskPipe } from "@shared/pipes/payment-type-mask.pipe";
import { GoBackComponent } from "@shared/components/go-back/go-back.component";
import { PhoneMaskPipe } from "@shared/pipes/phone-mask.pipe";
import { CurrencyMaskPipe } from "@shared/pipes/currency-mask.pipe";
import { ClientsSearchComponent } from "@shared/components/clients-search/clients-search.component";
import { ClientsSearchListComponent } from "@shared/components/clients-search-list/clients-search-list.component";
import { DateTimeFormatPipe } from "@shared/pipes/date-time-format.pipe";
import { BreadcrumbsComponent } from "@shared/components/breadcrumbs/breadcrumbs.component";
import { ScannerComponent } from "./components/scanner/scanner.component";
import { CardsComponent } from "./components/cards/cards.component";
import { InactivityTimePipe } from "@shared/pipes/inactivity-time.pipe";
import { TimeUnitPipe } from "@shared/pipes/time-unit.pipe";
import { ObjectToArrayPipe } from "@shared/pipes/object-to-array.pipe";
import { SoonComponent } from "./components/soon/soon.component";
import { AmountCurrencyPipe } from "./pipes/amount-currency.pipe";

@NgModule({
  declarations: [
    GoBackComponent,
    CreditCardMaskPipe,
    PaymentTypeMaskPipe,
    PhoneMaskPipe,
    CurrencyMaskPipe,
    DateTimeFormatPipe,
    ClientsSearchComponent,
    ClientsSearchListComponent,
    BreadcrumbsComponent,
    CardsComponent,
    InactivityTimePipe,
    TimeUnitPipe,
    ObjectToArrayPipe,
    SoonComponent,
    AmountCurrencyPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    TranslocoModule,
    ScannerComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GoBackComponent,
    CreditCardMaskPipe,
    PaymentTypeMaskPipe,
    PhoneMaskPipe,
    CurrencyMaskPipe,
    DateTimeFormatPipe,
    ClientsSearchComponent,
    BreadcrumbsComponent,
    CardsComponent,
    TimeUnitPipe,
    InactivityTimePipe,
    ObjectToArrayPipe,
    AmountCurrencyPipe,
    SoonComponent,
  ],
})
export class SharedModule {}

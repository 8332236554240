import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "objectToArrayPipe" })
export class ObjectToArrayPipe implements PipeTransform {
  public transform(value: any): any[] {
    if (!value) return [];

    return Object.keys(value).map((key) => ({ key, value: value[key] }));
  }
}

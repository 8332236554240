import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AgroexToastService, ToastType } from "ngx-agroex-toast";
import { Observable, catchError, retry, throwError, filter } from "rxjs";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public constructor(private _toastService: AgroexToastService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry(1),
      filter((err) => err.type !== 0),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) return throwError(() => error);

        if (!error.status) {
          this._toastService.addToast({
            toastType: ToastType.Error,
            title: "An unknown error occurred!",
          });

          return throwError(() => error);
        }

        if (error.status === 0 && error.error instanceof ProgressEvent) {
          this._toastService.addToast({
            toastType: ToastType.Error,
            title: "Internet connection is lost!",
          });

          return throwError(() => error);
        }

        if (error.error instanceof ErrorEvent) {
          this._toastService.addToast({
            toastType: ToastType.Error,
            title: `Error: ${error.error.message}`,
          });
        } else {
          switch (error.status) {
            case 400:
              this._toastService.addToast({
                toastType: ToastType.Error,
                title: "Bad request!",
              });
              break;
          }

          return throwError(() => error);
        }
      })
    );
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({ name: "currencyMaskPipe" })
export class CurrencyMaskPipe implements PipeTransform {
  private currencyPipe = new CurrencyPipe("en");

  public transform(amount: string | number, mustDivide?: boolean): string {
    if (mustDivide) {
      amount = (Number(amount) / 100).toString();
    }
    const newAmount: string = this.currencyPipe.transform(amount, "", "");
    return `${newAmount} UZS`;
  }
}

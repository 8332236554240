import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public constructor(private http: HttpClient) {}

  public get(
    api: string,
    url: string,
    params?: HttpParams | any,
    options?: any
  ): Observable<any> {
    const param = params ? `?${params.toString()}` : "";

    return this.http.get<any>(`${api}/${url}${param}`, options);
  }

  public post(
    api: string,
    url: string,
    body: any | null,
    options?: any
  ): Observable<any> {
    return this.http.post(`${api}/${url}`, body, options);
  }

  public put(
    api: string,
    url: string,
    body?: any | null,
    options?: any
  ): Observable<any> {
    return this.http.put(`${api}/${url}`, body, options);
  }

  public patch(
    api: string,
    url: string,
    body: any | null,
    id?: string,
    options?: any
  ): Observable<any> {
    return this.http.patch(`${api}/${url}${id}`, body, options);
  }

  public delete(api: string, url: string, options?: any): Observable<any> {
    return this.http.delete<any>(`${api}/${url}`, options);
  }
}

import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { IPath } from "@core/services/common/path-tracker/path.interface";
import { PATHS_LIST } from "@core/services/common/path-tracker/paths-list";

@Injectable({
  providedIn: "root",
})
export class PathTrackerService {
  private path$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private pathsList$: BehaviorSubject<IPath[]> = new BehaviorSubject<IPath[]>(
    []
  );

  public getPath(): Observable<string> {
    return this.path$.asObservable();
  }

  public setPath(path: string): void {
    const generatedPathsList: IPath[] = this.generatePathsList(path);

    this.path$.next(path);
    this.pathsList$.next(generatedPathsList);
  }
  public getPaths(): Observable<IPath[]> {
    return this.pathsList$.asObservable();
  }

  public setPaths(paths: IPath[]): void {
    this.pathsList$.next(paths);
  }

  private generatePathsList(link: string): IPath[] {
    const separatedLink: string[] = link.substring(1).split("/");
    const separatedFullLinks: string[] = [];
    const result: IPath[] = [];

    for (let linkIndex = 0; linkIndex < separatedLink.length; linkIndex++) {
      let fullLink: string;

      if (linkIndex === 0) {
        fullLink = "/" + separatedLink[linkIndex];
      }

      if (linkIndex > 0) {
        fullLink =
          separatedFullLinks[linkIndex - 1] + "/" + separatedLink[linkIndex];

        if (!PATHS_LIST.filter((e: IPath) => e.link === fullLink).length) {
          fullLink = separatedFullLinks[linkIndex - 1] + "/" + "something";
        }
      }

      separatedFullLinks.push(fullLink);

      const path: IPath =
        PATHS_LIST.find((path: IPath): boolean => path.link === fullLink) ||
        null;

      if (path) {
        path.index = linkIndex;

        result.push(path);
      }
    }

    return result;
  }
}

<div *transloco="let t" class="flex flex-col flex-auto min-w-0 md:mt-30">
  <div class="w-full flex items-center flex-col pb-6 px-6 sm:pb-10 sm:px-10">
    <ul
      class="grid grid-cols-1 md:grid-cols-3 gap-y-8 md:gap-y-4 md:gap-x-6 max-w-sm md:max-w-7xl -mt-16 sm:-mt-24 w-full"
    >
      <li
        *ngFor="let card of cards"
        class="relative flex flex-col rounded-md shadow overflow-hidden bg-card transition-shadow ease-in-out duration-150 border border-gray-300"
        [class]="card?.route ? 'cursor-pointer hover:shadow-lg' : ''"
      >
        <a
          class="flex flex-col flex-auto p-8 space-y-4"
          (click)="navigate(card?.route)"
        >
          <mat-icon
            class="icon-size-8"
            color="primary"
            [svgIcon]="card?.icon"
          ></mat-icon>
          <h3 class="text-2xl font-semibold">{{ t(card.title) }}</h3>
          <p class="text-secondary">
            {{ t(card.description) }}
          </p>
          <div *ngIf="!card?.route">
            <button
              [disabled]="!card?.routes?.front"
              class="mr-4 hover:scale-105 transition-all ease-in-out duration-150 shadow-lg"
              mat-flat-button
              [color]="'primary'"
              [routerLink]="card?.routes?.front"
            >
              {{ t("Breadcrumbs.Sides.Front") }}
            </button>
            <button
              [disabled]="!card?.routes?.back"
              class="hover:scale-105 transition-all ease-in-out duration-150 shadow-lg"
              mat-flat-button
              [color]="'accent'"
              [routerLink]="card?.routes?.back"
            >
              {{ t("Breadcrumbs.Sides.Back") }}
            </button>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>

/* tslint:disable:max-line-length */
import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: "services",
    title: "Список услуг",
    type: "collapsable",
    icon: "heroicons_outline:view-list",
    link: "/services",
    children: [
      {
        id: "navigation-clients",
        title: "Работа с клиентами",
        type: "basic",
        link: "/clients",
      },
      {
        id: "navigation-tasks-communications",
        title: "Задачи и коммуникации",
        type: "basic",
        link: "/tasks-communications",
      },
      {
        id: "navigation-reports",
        title: "Отчеты",
        type: "basic",
        link: "/reports",
      },
      {
        id: "navigation-credit-products",
        title: "Кредитные продукты ФЛ",
        type: "basic",
        link: "/credit-products",
      },
      {
        id: "navigation-microloans",
        title: "Микрозаймы",
        type: "basic",
        link: "/microloans",
      },
      {
        id: "navigation-car-loans",
        title: "Автокредит",
        type: "basic",
        link: "/car-loans",
      },
      {
        id: "navigation-appeals",
        title: "Обращения",
        type: "basic",
        link: "/appeals",
      },
      {
        id: "navigation-cash-register",
        title: "Регистрация сделок",
        type: "basic",
        link: "/cash-register",
      },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: "category-list",
    title: "Category List",
    type: "basic",
    icon: "heroicons_outline:view-list",
    link: "/categories",
    children: [
      {
        id: "cc",
        title: "Home",
        type: "basic",
        link: "/cc",
      },
      {
        id: "dd",
        title: "FAQs",
        type: "basic",
        link: "/dd",
      },
      {
        id: "ss",
        title: "Guides",
        type: "basic",
        link: "/ss",
      },
      {
        id: "aa",
        title: "Support",
        type: "basic",
        link: "/aa",
      },
    ],
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: "example",
    title: "Example",
    type: "basic",
    icon: "heroicons_outline:chart-pie",
    link: "/example",
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: "example",
    title: "Example",
    type: "basic",
    icon: "heroicons_outline:chart-pie",
    link: "/example",
  },
];

export const workingWithClientsNavigation: FuseNavigationItem[] = [
  {
    id: "working-with-clients",
    title: "Работа с клинетами",
    type: "collapsable",
    icon: "heroicons_outline:view-list",
    link: "/clients",
    children: [
      {
        id: "clients-search",
        title: "Поиск клиентов",
        type: "basic",
        link: "/clients",
      },
      {
        id: "create-physical-person-card",
        title: "Создать карточку ФЛ",
        type: "basic",
        link: "/clients/create-physical",
      },
      {
        id: "create-legal-person-card",
        title: "Создать карточку ЮЛ",
        type: "basic",
        link: "/clients/create-legal",
      },
      {
        id: "create-modules-person-card",
        title: "Создать карточку ИП",
        type: "basic",
        link: "/clients/create-modules",
      },
    ],
  },
];

export const carLoanNavigation: FuseNavigationItem[] = [
  {
    id: "car-loan",
    title: "Автокредит",
    type: "collapsable",
    icon: "heroicons_outline:view-list",
    link: "/car-loan",
    children: [
      {
        id: "car-loan-search",
        title: "Поиск автокредитов",
        type: "basic",
        link: "/car-loan",
      },
      {
        id: "create-car-loan",
        title: "Создать автокредит",
        type: "basic",
        link: "/car-loan/create",
      },
    ],
  },
];

export const loansNavigation: FuseNavigationItem[] = [
  {
    id: "loans",
    title: "Кредитные продукты ФЛ",
    type: "collapsable",
    icon: "heroicons_outline:view-list",
    link: "/credit-products",
    children: [
      {
        id: "car-loan",
        title: "Автокредит",
        type: "basic",
        link: "/car-loan",
      },
    ],
  },
];

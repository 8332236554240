<a
  class="inline-flex items-center leading-6 text-primary cursor-pointer hover:underline mb-5"
  (click)="onNavigate()"
>
  <span class="inline-flex items-center">
    <mat-icon
      class="icon-size-5 text-current"
      [svgIcon]="'heroicons_outline:chevron-left'"
    ></mat-icon>
    <span class="ml-1.5 font-medium leading-5 text-sm">{{ title }}</span>
  </span>
</a>

import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";

import { ApiService } from "@core/services/requests/api.service";

@Injectable({
  providedIn: "root",
})
export class ScannerService {
  private _api = "scan";

  private _endpoints = {
    connect: "Connect",
    disconnect: "Disconnect",
    scan: "GetImages", // This method allow you to start scanning and processing of the document manually
    getReaderFileImage: "GetReaderFileImage", // Get byte array with all scanned document
    getTextFieldByType: "GetTextFieldByType", // Get string from last scanned document
    clearResults: "ClearResults", // This method clears all previous scanning results, both from interface and inside SDK, freeing allocated memory for these resources
  };

  public constructor(private _http: ApiService) {}

  public connect(): Observable<string> {
    return this._http.post(this._api, this._endpoints.connect, "");
  }

  public disconnect(): Observable<string> {
    return this._http.post(this._api, this._endpoints.disconnect, "");
  }

  public doScan(): Observable<number> {
    return this._http.get(this._api, this._endpoints.scan);
  }

  public getTextFieldByType(queryParams: Params): Observable<string> {
    const params = new HttpParams({ fromObject: { ...queryParams } });

    return this._http.get(
      this._api,
      this._endpoints.getTextFieldByType,
      params
    );
  }

  public clearResults(): Observable<string> {
    return this._http.post(this._api, this._endpoints.clearResults, "");
  }
}

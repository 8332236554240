import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({ name: "currencyMaskPipe" })
export class CurrencyMaskPipe implements PipeTransform {
  private currencyPipe = new CurrencyPipe("en");
  public transform(amount: string | number): string {
    const newAmount = this.currencyPipe.transform(amount, "", "");
    return `${newAmount} UZS`;
  }
}

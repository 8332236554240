export enum ScannerFieldsEnum {
  Type = 0,
  CountryCode = 1,
  PassportNumber = 2,
  DateOfExpiry = 3,
  DateOfIssue = 4,
  DateOfBirth = 5,
  PlaceOfBirth = 6,
  PINFL = 7,
  Surname = 8,
  GivenNames = 9,
  Nationality = 11,
  Sex = 12,
  StatePersonalization = 24,
  SurnameAndName = 25,
  CountryCodeRepeats = 26, //TODO. WHY?
  UnknownField1 = 35, //TODO. What is it?
  PINFLRepeats = 36, //TODO. WHY?
  NationalityRepeats = 38, //TODO. WHY?
  LastRowInPassport = 51, //TODO. Rename
  UnknownField2 = 80,
  UnknownField3 = 81,
  UnknownField4 = 82,
  UnknownField5 = 84,
}

<div class="w-full mt-8">
  <div
    class="flex flex-col flex-auto min-w-0 sm:overflow-hidden card p-0 dark:bg-transparent"
  >
    <div
      class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between"
    >
      <!-- Main -->
      <div class="flex flex-auto overflow-hidden">
        <!-- Auto Loans List -->
        <div class="flex flex-col flex-auto overflow-hidden sm:overflow-y-auto">
          <ng-container
            *ngIf="clientData.customerData.length; else noCustomersData"
          >
            <div class="grid">
              <!-- Header -->
              <div
                class="custom-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
              >
                <div *ngFor="let column of columns" class="hidden sm:block">
                  {{ column.title }}
                </div>

                <div class="hidden sm:block"></div>
              </div>

              <!-- Rows -->
              <ng-container *ngFor="let customer of clientData.customerData">
                <div
                  class="custom-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b"
                >
                  <!-- Username -->
                  <div class="truncate">
                    {{ customer.username }}
                  </div>

                  <!-- FullName -->
                  <div class="truncate">
                    {{ concatFullName(customer) }}
                  </div>

                  <!-- Address -->
                  <div class="truncate">
                    {{ customer.actualAddress?.fullAddress ?? "-" }}
                  </div>

                  <!-- PINFL -->
                  <div class="truncate">
                    {{ customer.pinfl }}
                  </div>

                  <!-- DOB -->
                  <div class="truncate">
                    {{ customer.dob | dateTimeFormat : 'display': 'dateInput' }}
                  </div>

                  <!-- Actions -->
                  <div class="flex gap-4">
                    <mat-radio-group
                      *ngIf="
                        availableActionsWithClient.includes(actions.Choose)
                      "
                      [formControl]="selectedClient"
                    >
                      <mat-radio-button
                        type="radio"
                        [value]="customer.customerId"
                      />
                    </mat-radio-group>
                    <span
                      *ngIf="availableActionsWithClient.includes(actions.More)"
                      class="text-gray-400 cursor-pointer"
                      routerLink="{{ customer.customerId }}"
                      >Подробнее</span
                    >
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-template #noCustomersData>
            <div class="p-8 sm:p-16 border-t text-center space-y-4">
              <h4 class="text-4xl font-semibold tracking-tight">
                Нет клиентов
              </h4>
              <a
                mat-flat-button
                routerLink="/clients/create/physical"
                class="btn"
                [color]="'primary'"
              >
                Создать клиента
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<nav *ngIf="paths.length > 0" class="flex flex-row py-4 px-4 items-center">
  <div
    *transloco="let t"
    class="flex flex-row items-center px-4 rounded-md bg-white"
    style="min-height: 50px; z-index: 5; box-shadow: 0 3px 2px -2px lightgray"
  >
    <div class="flex flex-row text-gray-400">
      <a class="text-primary cursor-pointer hover:underline" routerLink="">{{
        t("Breadcrumbs.General")
      }}</a>
      <mat-icon
        class="icon-size-5 text-current mx-2"
        [svgIcon]="'heroicons_outline:chevron-right'"
      ></mat-icon>
    </div>
    <div class="flex flex-row text-gray-400" *ngFor="let path of paths">
      <a
        [ngClass]="{
          'text-primary': path.index + 1 < paths.length && path.availableToNavigate,
          'cursor-pointer hover:underline':
            path.availableToNavigate && path.index + 1 < paths.length
        }"
        (click)="navigate(path, path.index + 1 === paths.length)"
        >{{ t(path.title) }}</a
      >
      <mat-icon
        *ngIf="path.index + 1 < paths.length"
        class="icon-size-5 text-current mx-2"
        [svgIcon]="'heroicons_outline:chevron-right'"
      ></mat-icon>
    </div>
  </div>
  <div
    style="
      transform: rotate(-45deg);
      background-color: white;
      min-width: 38.7px;
      min-height: 38.7px;
      max-width: 35px;
      max-height: 35px;
      margin-left: -25px;
      box-shadow: 0 3px 2px -2px lightgray;
    "
    class="rounded-md"
  ></div>
</nav>
<!--<app-go-back-->
<!--  *ngIf="paths.length === 1"-->
<!--  [title]="'На главную'"-->
<!--  [link]="'/'"-->
<!--&gt;</app-go-back>-->

export enum LanguagesEnum {
  ENG = "English",
  RUS = "Русский",
  UZB = "O'zbek",
}

export enum LanguagesISO639$1FormatEnum {
  ENG = "en",
  RUS = "ru",
  UZB = "uz",
}

export const TranslocoConfig = {
  availableLangs: [
    {
      id: LanguagesISO639$1FormatEnum.ENG,
      label: LanguagesEnum.ENG,
    },
    {
      id: LanguagesISO639$1FormatEnum.RUS,
      label: LanguagesEnum.RUS,
    },
    {
      id: LanguagesISO639$1FormatEnum.UZB,
      label: LanguagesEnum.UZB,
    },
  ],
  defaultLang: LanguagesISO639$1FormatEnum.RUS,
  fallbackLang: LanguagesISO639$1FormatEnum.RUS,
  reRenderOnLangChange: true,
  prodMode: true,
};

import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import {
  Navigation,
  NavigationEnum,
} from "app/core/navigation/navigation.types";
import {
  carLoanNavigation,
  defaultNavigation,
  loansNavigation,
  workingWithClientsNavigation,
} from "@mock-api/common/navigation/data";
import { FuseNavigationItem } from "@fuse/components/navigation";

@Injectable({
  providedIn: "root",
})
export class NavigationService implements OnDestroy {
  public destroy$ = new Subject();
  public navigationItemList$ = new BehaviorSubject(null);

  private _navigation: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<
    FuseNavigationItem[]
  >(1);
  private _navigationVisible: ReplaySubject<boolean> =
    new ReplaySubject<boolean>();
  private navigationList: Navigation = {
    compact: [],
    default: defaultNavigation,
    futuristic: [],
    horizontal: [],
    workingWithClients: workingWithClientsNavigation,
    loans: loansNavigation,
    carLoan: carLoanNavigation,
  };

  public constructor(private _httpClient: HttpClient) {}

  public get navigation$(): Observable<FuseNavigationItem[]> {
    return this._navigation.asObservable();
  }

  public get itemsFullList$(): Observable<FuseNavigationItem> {
    return this.navigationItemList$.asObservable();
  }

  public get navigationVisibility$(): Observable<boolean> {
    return this._navigationVisible.asObservable();
  }

  public setNavigationVisibility(parent: boolean): void {
    this._navigationVisible.next(parent);
  }

  public get(): Observable<Navigation> {
    return this._httpClient.get<Navigation>("api/common/navigation");
  }

  public set(type: NavigationEnum): Observable<FuseNavigationItem> {
    this._navigation.next(this.navigationList[type]);

    return this._httpClient.get<FuseNavigationItem>("api/common/navigation");
  }

  public navigationMap(parent, child): FuseNavigationItem {
    for (const count of child) {
      parent.type = "basic";
      parent.children.push({
        id: count.id,
        title: count.name,
        link: `/list/${parent.id}/subcategory/${count.id}`,
        type: "basic",
      });
    }

    return parent;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}

import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import {
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from "@fuse/components/navigation";
import { NavigationService } from "app/core/navigation/navigation.service";
import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";

@Component({
  selector: "dense-layout",
  templateUrl: "./dense.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class DenseLayoutComponent
  extends UnsubscribeDirective
  implements OnInit
{
  public isScreenSmall: boolean;
  public navigation: FuseNavigationItem[];
  public navigationAppearance: "default" | "dense" = "dense";

  public constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService
  ) {
    super();
  }

  public get currentYear(): number {
    return new Date().getFullYear();
  }

  public ngOnInit(): void {
    this.trackNavigation();
    this.trackOnMediaChange();
  }

  public toggleNavigation(name: string): void {
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) navigation.toggle();
  }

  public toggleNavigationAppearance(): void {
    this.navigationAppearance =
      this.navigationAppearance === "default" ? "dense" : "default";
  }

  private trackNavigation(): void {
    this.subscribeTo = this._navigationService.navigation$.subscribe({
      next: (navigation: FuseNavigationItem[]): void => {
        this.navigation = navigation;
      },
    });
  }

  private trackOnMediaChange(): void {
    this.subscribeTo = this._fuseMediaWatcherService.onMediaChange$.subscribe({
      next: ({ matchingAliases }): void => {
        this.isScreenSmall = !matchingAliases.includes("md");
        this.navigationAppearance = this.isScreenSmall ? "default" : "dense";
      },
    });
  }
}

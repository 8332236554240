import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-go-back",
  templateUrl: "./go-back.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoBackComponent {
  @Input() public title: string;
  @Input() public link: string | null = null;

  public constructor(private _router: Router, private _location: Location) {}

  public onNavigate(): void {
    if (this.link) {
      void this._router.navigate([this.link]);

      return;
    }

    this._location.back();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";

import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";
import { PathTrackerService } from "@core/services/common/path-tracker/path-tracker.service";
import { IPath } from "@core/services/common/path-tracker/path.interface";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent
  extends UnsubscribeDirective
  implements OnInit
{
  public paths: IPath[] = [];

  public constructor(
    private _pathTracker: PathTrackerService,
    private _cdr: ChangeDetectorRef,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getPaths();
  }

  public navigate(path: IPath, isCurrentPath: boolean): void {
    if (path.availableToNavigate && !isCurrentPath)
      void this._router.navigateByUrl(path.link);
  }

  private getPaths(): void {
    this.subscribeTo = this._pathTracker.getPaths().subscribe({
      next: (paths: IPath[]): void => {
        this.paths = paths;
        this._cdr.detectChanges();
      },
    });
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ApiService } from "@core/services/requests/api.service";
import { IRequest } from "@core/interfaces/request.interface";
import { IResponse } from "@core/interfaces/response.interface";
import {
  IClientData,
  IClientsData,
} from "@departments/interfaces/clients/create-customer.interface";
import { ICheckExistenceRequest } from "@departments/interfaces/clients/request-body/check-existence-request.interface";
import { IConfirmationRequest } from "@departments/interfaces/clients/request-body/confirmation-request.interface";
import { IGetCustomerListRequest } from "@departments/interfaces/clients/request-body/get-customer-list.request.interface";
import { ICheckExistenceResponse } from "@departments/interfaces/clients/response-body/check-existence-response.interface";
import { IConfirmationRequestResponse } from "@departments/interfaces/clients/response-body/confirmation-request-response.interface";
import { ResponseStatusesEnum } from "@core/enums/response-statuses.enum";

interface FormState {
  stepName: string;
  formData: any;
}

@Injectable({
  providedIn: "root",
})
export class PhysicalClientService {
  private _api = "/gateway/customer";
  private _endpoints = {
    existence: "existence",
    create: "create",
    list: "list",
    confirmation: "confirmation-request",
  };
  private _formState: BehaviorSubject<FormState[]> = new BehaviorSubject<
    FormState[]
  >([]);
  private _otpId: string;

  public constructor(private _http: ApiService) {}

  public get otpId(): string {
    return this._otpId;
  }

  public get formState(): FormState[] {
    return this._formState.value;
  }

  public set formState(formState: FormState[] | []) {
    this._formState.next(formState);
  }

  public setFormState(stepName: string, formData: any): void {
    const formState = this._formState.value;

    const stepIndex = formState.findIndex((step) => step.stepName === stepName);

    if (formState.some((step) => step.stepName === stepName)) {
      formState[stepIndex] = { stepName, formData };
    } else {
      formState.push({ stepName, formData });
    }

    this._formState.next(formState);
  }

  public setOtpId(otpId: string): void {
    this._otpId = otpId;
  }

  public getMyIdData(): string[] {
    return [this.formState[0].formData.pinfl, this.formState[0].formData.dob];
  }

  public getOtpData(): IConfirmationRequest {
    return {
      phone: this.formState[0].formData.username,
      pinfl: this.formState[0].formData.pinfl,
      dob: this.formState[0].formData.dob,
    };
  }

  public getFullFormData(): any {
    const formState = this.formState;

    const fullFormData = formState.reduce((acc, curr) => {
      if (curr.formData) {
        return { ...acc, ...curr.formData };
      }

      return acc;
    }, {});

    return fullFormData;
  }

  public checkClientExistence(
    body: IRequest<ICheckExistenceRequest>
  ): Observable<IResponse<ICheckExistenceResponse>> {
    return this._http.post(this._api, this._endpoints.existence, body);
  }

  public createCustomer(
    body: IRequest<IClientsData>
  ): Observable<IResponse<IClientsData>> {
    return this._http.post(this._api, this._endpoints.create, body);
  }

  public getCustomersList(
    body: IRequest<IGetCustomerListRequest>
  ): Observable<IResponse<IClientsData>> {
    return this._http.post(this._api, this._endpoints.list, body);
  }

  public getCustomerById(id: string): Observable<IResponse<IClientData>> {
    return this._http.get(this._api, id);
  }

  public sendConfirmationRequest(
    body: IRequest<IConfirmationRequest>
  ): Observable<IResponse<IConfirmationRequestResponse>> {
    return this._http.post(this._api, this._endpoints.confirmation, body);
  }

  // TODO: Methods for references (СПРАВОЧНИКИ), possible we will move it to separate service to reuse
  public getContactTypes(): Observable<
    IResponse<{ value: string; label: string }[]>
  > {
    return of({
      data: [
        {
          value: "PHONE",
          label: "Мобильный",
        },
        {
          value: "EMAIL",
          label: "Электронная почта",
        },
        {
          value: "FAX",
          label: "Факс",
        },
      ],
      isFinish: true,
      status: ResponseStatusesEnum.Success,
    });
  }

  public getDocumentTypes(): Observable<
    IResponse<{ value: string; label: string }[]>
  > {
    return of({
      data: [
        {
          value: "RESIDENCE_PERMIT_STATELESS",
          label: "Вид на жительство лица без гражданства",
        },
        {
          value: "RESIDENCE_PERMIT_FOREIGNER",
          label: "Вид на жительство иностранца",
        },
        {
          value: "PASSPORT_CITIZEN",
          label: "Паспорт гражданина Республики Узбекистан",
        },
        {
          value: "PASSPORT_UNDERAGE",
          label:
            "Паспорт гражданина Республики Узбекистан (несовершеннолетний)",
        },
      ],
      isFinish: true,
      status: ResponseStatusesEnum.Success,
    }).pipe();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";

import {
  IClient,
  IClientsData,
} from "@features/departments/interfaces/clients/create-customer.interface";
import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";
import { ITableColumn } from "@shared/interfaces/table-columns.interface";
import {
  CLIENTS_SEARCH_LIST_ACTION_COLUMN,
  CLIENTS_SEARCH_LIST_COLUMNS,
} from "@shared/components/clients-search-list/clients-search-list-columns";
import { ActionsEnum } from "@shared/enums/enums";

@Component({
  selector: "app-clients-search-list",
  templateUrl: "./clients-search-list.component.html",
  styleUrls: ["./clients-search-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsSearchListComponent
  extends UnsubscribeDirective
  implements OnInit, OnChanges
{
  @Input() public clientData: IClientsData;
  @Input() public availableActionsWithClient: ActionsEnum[];

  @Output() public selectClientId: EventEmitter<string> = new EventEmitter();

  public selectedClient: FormControl<string> = new FormControl("");
  public columns: ITableColumn[] = CLIENTS_SEARCH_LIST_COLUMNS;
  public columnActions: ITableColumn = CLIENTS_SEARCH_LIST_ACTION_COLUMN;
  public actions = ActionsEnum;

  public ngOnInit(): void {
    this.listenSelectedClientChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["availableActionsWithClient"].currentValue.length &&
      !changes["availableActionsWithClient"].previousValue &&
      !this.columns.find(
        (e: ITableColumn) => e.name === this.columnActions.name
      )
    ) {
      this.columns.push(this.columnActions);
    }
  }

  public concatFullName(client: IClient): string {
    return `${client.lastName} ${client.firstName} ${client.patronym}`;
  }

  private listenSelectedClientChanges(): void {
    this.subscribeTo = this.selectedClient.valueChanges.subscribe({
      next: (clientId: string): void => {
        this.selectClientId.emit(clientId);
      },
    });
  }
}

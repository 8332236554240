import { Route } from "@angular/router";

export const appRoutes: Route[] = [
  {
    path: "",
    loadChildren: () =>
      import("@features/features.module").then((m) => m.FeaturesModule),
  },
  {
    path: "404-not-found",
    pathMatch: "full",
    loadComponent: () =>
      import("@core/pages/error-404/error-404.component").then(
        (m) => m.Error404Component
      ),
  },
  {
    path: "500-internal-server-error",
    pathMatch: "full",
    loadComponent: () =>
      import("@core/pages/error-500/error-500.component").then(
        (m) => m.Error500Component
      ),
  },
  { path: "**", redirectTo: "404-not-found" },
];

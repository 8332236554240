import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Router } from "@angular/router";

import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  switchMap,
  take,
  throwError,
} from "rxjs";

import {
  AuthService,
  ITokenInternalResponse,
} from "@features/auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshingTokensInProgress = false;
  private refreshedAccessTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  public constructor(
    private _authService: AuthService,
    private _router: Router
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const newReq: HttpRequest<any> = this.getClonedRequestWithToken(req);

    return next.handle(newReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !this._router.url.includes("sign-in")
        ) {
          if (error.url.includes("refresh-token")) {
            this._authService.signOut();
            location.reload();
          }

          if (this._authService.refreshToken) {
            return this.handleAuthErrors(newReq, next);
          }

          this._authService.signOut();
          location.reload();
        }

        return throwError(() => error);
      })
    );
  }

  private getClonedRequestWithToken(req: HttpRequest<any>): HttpRequest<any> {
    if (!req.url.includes("token")) {
      return req.clone({
        headers: req.headers.set(
          "Authorization",
          "Bearer " + this._authService.accessToken || ""
        ),
      });
    }

    return req.clone();
  }

  private handleAuthErrors(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshingTokensInProgress) {
      this.isRefreshingTokensInProgress = true;
      this.refreshedAccessTokenSubject.next(null);

      return this._authService.refreshAccessToken().pipe(
        switchMap((response: ITokenInternalResponse) => {
          this.isRefreshingTokensInProgress = false;
          this._authService.accessToken = response.token;
          this._authService.refreshToken = response.refreshToken;
          this.refreshedAccessTokenSubject.next(response.token);

          return next.handle(
            request = this.getClonedRequestWithToken(request)
          );
        }),
        catchError((err) => {
          this.isRefreshingTokensInProgress = false;
          this._authService.accessToken = "";
          this._authService.refreshToken = "";
          this._authService.signOut();

          return throwError(() => err);
        })
      );
    }

    return this.refreshedAccessTokenSubject.pipe(
      filter((token: string): boolean => token !== null),
      take(1),
      switchMap(() => next.handle(this.getClonedRequestWithToken(request)))
    );
  }
}

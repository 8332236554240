import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "inactivityTime" })
export class InactivityTimePipe implements PipeTransform {
  private static convertTime(time: number): number {
    switch (true) {
      case time < 60:
        return Math.ceil(time);
      case time >= 60:
        return Math.round(time / 60);
    }
  }

  public transform(time: number): string {
    let convertedTime: number;

    if (time) {
      convertedTime = InactivityTimePipe.convertTime(time);

      return ` ${convertedTime}`;
    }
  }
}

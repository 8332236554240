import { Injectable } from "@angular/core";

import { BehaviorSubject, fromEvent, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MouseStateService {
  private mouse$ = fromEvent(document, "mousemove");
  private mouseInactiveTime$ = new BehaviorSubject<number>(0);

  public get getMouseMovement(): Observable<Event> {
    return this.mouse$;
  }

  public get getMouseInactiveTime(): Observable<number> {
    return this.mouseInactiveTime$.asObservable();
  }

  public setDefaultMouseInactiveTime(): void {
    this.mouseInactiveTime$.next(0);
  }

  public setMouseInactiveTime(): void {
    this.mouseInactiveTime$.next(this.mouseInactiveTime$.value + 1);
  }
}

import {Component, Input} from "@angular/core";

@Component({
  selector: "app-soon",
  templateUrl: "./soon.component.html",
  styleUrls: ["./soon.component.scss"],
})
export class SoonComponent {
  @Input() public title: string;
}

import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const atLeastOneValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (
    control.get("username")?.value !== "" ||
    control.get("pinfl")?.value !== "" ||
    control.get("documentSerial")?.value !== "" ||
    control.get("documentNumber")?.value !== ""
  ) {
    return null;
  } else {
    return { atLeastone: "error" };
  }
};

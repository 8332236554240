import {
  Component,
  Inject,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { combineLatest, filter, map } from "rxjs";
import { FuseConfigService } from "@fuse/services/config";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { FusePlatformService } from "@fuse/services/platform";
import { FUSE_VERSION } from "@fuse/version";
import { Layout } from "app/layout/layout.types";
import { AppConfig } from "app/core/config/app.config";
import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";

@Component({
  selector: "layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent extends UnsubscribeDirective implements OnInit {
  public config: AppConfig;
  public layout: Layout;
  public scheme: "dark" | "light";
  public theme: string;

  public constructor(
    @Inject(DOCUMENT) private _document: any,
    private _activatedRoute: ActivatedRoute,
    private _renderer2: Renderer2,
    private _router: Router,
    private _fuseConfigService: FuseConfigService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fusePlatformService: FusePlatformService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscribeTo = combineLatest([
      this._fuseConfigService.config$,
      this._fuseMediaWatcherService.onMediaQueryChange$([
        "(prefers-color-scheme: dark)",
        "(prefers-color-scheme: light)",
      ]),
    ])
      .pipe(
        map(([config, mql]) => {
          const options = {
            scheme: config.scheme,
            theme: config.theme,
          };

          if (config.scheme === "auto") {
            options.scheme = mql.breakpoints["(prefers-color-scheme: dark)"]
              ? "dark"
              : "light";
          }

          return options;
        })
      )
      .subscribe((options): void => {
        this.scheme = options.scheme;
        this.theme = options.theme;

        this._updateScheme();
        this._updateTheme();
      });

    this.subscribeTo = this._fuseConfigService.config$.subscribe(
      (config: AppConfig): void => {
        this.config = config;

        this._updateLayout();
      }
    );

    this.subscribeTo = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((): void => {
        this._updateLayout();
      });

    this._renderer2.setAttribute(
      this._document.querySelector("[ng-version]"),
      "fuse-version",
      FUSE_VERSION
    );

    this._renderer2.addClass(
      this._document.body,
      this._fusePlatformService.osName
    );
  }

  private _updateLayout(): void {
    let route = this._activatedRoute;

    while (route.firstChild) {
      route = route.firstChild;
    }

    this.layout = this.config.layout;

    const layoutFromQueryParam = route.snapshot.queryParamMap.get(
      "layout"
    ) as Layout;

    if (layoutFromQueryParam) {
      this.layout = layoutFromQueryParam;

      if (this.config) {
        this.config.layout = layoutFromQueryParam;
      }
    }

    const paths: ActivatedRoute[] = route.pathFromRoot;

    paths.forEach((path: ActivatedRoute): void => {
      if (
        path.routeConfig &&
        path.routeConfig.data &&
        path.routeConfig.data.layout
      ) {
        this.layout = path.routeConfig.data.layout;
      }
    });
  }

  private _updateScheme(): void {
    this._document.body.classList.remove("light", "dark");
    this._document.body.classList.add(this.scheme);
  }

  private _updateTheme(): void {
    this._document.body.classList.forEach((className: string): void => {
      if (className.startsWith("theme-")) {
        this._document.body.classList.remove(
          className,
          className.split("-")[1]
        );
      }
    });

    this._document.body.classList.add(this.theme);
  }
}

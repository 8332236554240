import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";

import { take } from "rxjs";
import { AvailableLangs, TranslocoService } from "@ngneat/transloco";
import {
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from "@fuse/components/navigation";

import { LANGUAGE_KEY } from "@shared/constants/storage-keys";
import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";
import { LanguagesISO639$1FormatEnum } from "@core/config/transloco.config";

@Component({
  selector: "languages",
  templateUrl: "./languages.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "languages",
})
export class LanguagesComponent extends UnsubscribeDirective implements OnInit {
  public availableLanguages: AvailableLangs;
  public activeLang: LanguagesISO639$1FormatEnum;
  public flagCodes: Record<
    LanguagesISO639$1FormatEnum,
    LanguagesISO639$1FormatEnum
  >;

  public constructor(
    private _fuseNavigationService: FuseNavigationService,
    private _translocoService: TranslocoService,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.defineAvailableLanguages();
    this.trackLangChanges();
    this.defineFlagCodes();
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  public setActiveLang(lang: string): void {
    const currentLanguage = this._translocoService.getActiveLang();

    if (lang === currentLanguage) {
      return;
    }

    localStorage.setItem(LANGUAGE_KEY, lang);
    this._translocoService.setActiveLang(lang);
    this.reloadCurrentRoute();
  }

  private trackLangChanges(): void {
    this.subscribeTo = this._translocoService.langChanges$.subscribe({
      next: (activeLang: LanguagesISO639$1FormatEnum): void => {
        this.activeLang = activeLang;

        this.updateNavigation();
      },
    });
  }

  private defineAvailableLanguages(): void {
    this.availableLanguages = this._translocoService.getAvailableLangs();
  }

  private defineFlagCodes(): void {
    this.flagCodes = {
      en: LanguagesISO639$1FormatEnum.ENG,
      ru: LanguagesISO639$1FormatEnum.RUS,
      uz: LanguagesISO639$1FormatEnum.UZB,
    };
  }

  private reloadCurrentRoute(): void {
    const currentUrl = this._router.url;

    this._router
      .navigateByUrl("/", { skipLocationChange: true })
      .then((): void => {
        void this._router.navigate([currentUrl]);
      });
  }

  private updateNavigation(): void {
    const navComponent: FuseVerticalNavigationComponent =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        "mainNavigation"
      );

    if (!navComponent) {
      return null;
    }

    const navigation: FuseNavigationItem[] = navComponent.navigation;
    const projectDashboardItem: FuseNavigationItem =
      this._fuseNavigationService.getItem("dashboards.project", navigation);
    const analyticsDashboardItem: FuseNavigationItem =
      this._fuseNavigationService.getItem("dashboards.analytics", navigation);

    if (projectDashboardItem) {
      this.selectTranslate("Project", projectDashboardItem, navComponent);
    }

    if (analyticsDashboardItem) {
      this.selectTranslate("Analytics", analyticsDashboardItem, navComponent);
    }
  }

  private selectTranslate(
    key: "Project" | "Analytics",
    fuseNavigationItem: FuseNavigationItem,
    navComponent: FuseVerticalNavigationComponent
  ): void {
    this.subscribeTo = this._translocoService
      .selectTranslate(key)
      .pipe(take(1))
      .subscribe({
        next: (translation): void => {
          fuseNavigationItem.title = translation;

          navComponent.refresh();
        },
      });
  }
}

import { Pipe, PipeTransform } from "@angular/core";

export type PaymentMethodType = "uzcard" | "humo" | "cash";

@Pipe({ name: "paymentTypeMaskPipe" })
export class PaymentTypeMaskPipe implements PipeTransform {
  public transform(paymentMethod: PaymentMethodType): string {
    switch (paymentMethod) {
      case "cash":
        return "Core.Cash";
      case "humo":
        return "Core.Humo";
      case "uzcard":
        return "Core.UzCard";
    }
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { DATE_FORMATS_MOMENTS } from "@shared/constants/date-formats";

type type = "display" | "parse";
type format =
  | "dateInput"
  | "dateTime"
  | "monthYearLabel"
  | "dateA11yLabel"
  | "monthYearA11yLabel";

@Pipe({
  name: "dateTimeFormat",
})
export class DateTimeFormatPipe implements PipeTransform {
  public transform(value: string, type: type, format: format): string {
    return moment(value).format(DATE_FORMATS_MOMENTS[type][format]);
  }
}

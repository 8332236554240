import { ClassProvider } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AcceptLanguageInterceptor } from "@core/interceptors/accept-language.interceptor";
import { HttpErrorInterceptor } from "./error.interceptor";
import { XRealmInterceptor } from "@core/interceptors/x-realm.interceptor";

export const Interceptors: ClassProvider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: XRealmInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AcceptLanguageInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
];

export const DATE_FORMATS_MOMENTS = {
  parse: {
    dateInput: "DD.MM.YYYY",
  },

  display: {
    dateInput: "DD.MM.YYYY",
    dateTime: "DD.MM.YYYY HH:mm",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export const DATE_FORMAT_DEFAULT = "YYYY.MM.DD";

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

import { forkJoin, Observable } from "rxjs";

import { ScannerService } from "@core/services/devices/scanner/scanner.service";
import { ScannerFieldsEnum } from "@core/services/devices/scanner/enums/scanner-fields.enum";
import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";
import { ScannerHelper } from "@core/services/devices/scanner/helpers/scanner.helper";
import { IClientDocScan } from "@core/services/devices/scanner/interfaces/client-scan.interface";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-scanner",
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: "./scanner.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerComponent extends UnsubscribeDirective implements OnInit {
  @Input() public isScanButtonDisabled = false;
  @Input() public isResetButtonDisabled = false;

  @Output() public resetSearching: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() public scanDocument: EventEmitter<IClientDocScan> =
    new EventEmitter<IClientDocScan>();
  @Output() public scanningStarted: EventEmitter<void> =
    new EventEmitter<void>();

  public countryCode$: Observable<string>;
  public documentSerialNumber$: Observable<string>;
  public PINFLFromScanner$: Observable<string>;
  public DOBFromScanner$: Observable<string>;

  public clientDocData: IClientDocScan = {};

  public constructor(private _scannerService: ScannerService) {
    super();
  }

  public ngOnInit(): void {
    this.initAllObservers();
  }

  public onResetSearching(): void {
    this.isResetButtonDisabled = false;
    this.isScanButtonDisabled = false;
    this.clearScannerResults();
    this.resetSearching.emit();
  }

  public onScanDocument(): void {
    this.isResetButtonDisabled = true;
    this.isScanButtonDisabled = true;
    this.onScanningStarted();

    this.subscribeTo = this._scannerService.doScan().subscribe({
      next: (): void => {
        setTimeout((): void => {
          this.subscribeTo = forkJoin([
            this.countryCode$,
            this.documentSerialNumber$,
            this.PINFLFromScanner$,
            this.DOBFromScanner$,
          ]).subscribe({
            next: ([
              countryCode,
              documentSerialNumber,
              pinfl,
              dateOfBirth,
            ]: string[]): void => {
              this.clientDocData.countryCode = countryCode || "";
              this.clientDocData.documentSerialNumber =
                documentSerialNumber || "";
              this.clientDocData.dateOfBirth = dateOfBirth || "";
              this.clientDocData.pinfl = pinfl || "";

              this.clearScannerResults();

              this.scanDocument.emit(
                ScannerHelper.convertClientData(this.clientDocData)
              );

              this.isResetButtonDisabled = false;
            },
            error: (): void => {
              this.onResetSearching();
              alert("Something went wrong");
            },
          });
        }, 4000); // TODO. Find better solution (do requests when the scan is over).
      },
      error: (): void => {
        this.onResetSearching();
        alert("Something went wrong");
      },
    });
  }

  private initAllObservers(): void {
    this.getCountryCodeFromScanner();
    this.getPINFLFromScanner();
    this.getDocumentSerialNumberFromScanner();
    this.getDateOfBirthFromScanner();
  }

  private onScanningStarted(): void {
    this.scanningStarted.emit();
  }

  private getPINFLFromScanner(): void {
    this.PINFLFromScanner$ = this._scannerService.getTextFieldByType({
      AType: ScannerFieldsEnum.PINFL,
    });
  }

  private getDateOfBirthFromScanner(): void {
    this.DOBFromScanner$ = this._scannerService.getTextFieldByType({
      AType: ScannerFieldsEnum.DateOfBirth,
    });
  }

  private getDocumentSerialNumberFromScanner(): void {
    this.documentSerialNumber$ = this._scannerService.getTextFieldByType({
      AType: ScannerFieldsEnum.PassportNumber,
    });
  }

  private getCountryCodeFromScanner(): void {
    this.countryCode$ = this._scannerService.getTextFieldByType({
      AType: ScannerFieldsEnum.CountryCode,
    });
  }

  private clearScannerResults(): void {
    this.subscribeTo = this._scannerService.clearResults().subscribe(); // TODO. Define do we need to clear cash.
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "phoneMaskPipe" })
export class PhoneMaskPipe implements PipeTransform {
  public transform(phone: string | number): string {
    if (phone) {
      const phoneString = phone.toString();

      const areaCodeStr = phoneString.slice(0, 2);
      const firstSectionStr = phoneString.slice(2, 5);
      const midSectionStr = phoneString.slice(5, 7);
      const lastSectionStr = phoneString.slice(7, 9);

      return `(${areaCodeStr}) ${firstSectionStr}-${midSectionStr}-${lastSectionStr}`;
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import {
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from "@fuse/components/navigation";
import { NavigationService } from "app/core/navigation/navigation.service";
import { UnsubscribeDirective } from "@core/directives/unsubscribe.directive";
import { MouseStateService } from "@core/state/mouse-state.service";
import { ALLOWABLE_INACTIVITY } from "@shared/constants/allowable-inactivity";

@Component({
  selector: "classic-layout",
  templateUrl: "./classic.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ClassicLayoutComponent
  extends UnsubscribeDirective
  implements OnInit, AfterViewInit
{
  public isScreenSmall: boolean;
  public isNavigationVisible = false;
  public navigation: FuseNavigationItem[];
  public countdown = 0;

  protected readonly ALLOWABLE_INACTIVITY = ALLOWABLE_INACTIVITY;

  public constructor(
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _cdr: ChangeDetectorRef,
    private _mouseStateService: MouseStateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.trackNavigationVisibility();
    this.trackNavigation();
    this.trackOnMediaChange();
    this.trackOnMouseMove();
  }

  public ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  public toggleNavigation(name: string): void {
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) navigation.toggle();
  }

  private trackOnMouseMove(): void {
    this.subscribeTo = this._mouseStateService.getMouseInactiveTime.subscribe(
      (time: number): void => {
        this.countdown = time > ALLOWABLE_INACTIVITY - 60 ? time : 0;
      }
    );
  }

  private trackNavigationVisibility(): void {
    this.subscribeTo = this._navigationService.navigationVisibility$.subscribe({
      next: (isVisible: boolean): void => {
        this.isNavigationVisible = isVisible;
      },
    });
  }

  private trackNavigation(): void {
    this.subscribeTo = this._navigationService.navigation$.subscribe({
      next: (navigation: FuseNavigationItem[]): void => {
        this.navigation = navigation;
      },
    });
  }

  private trackOnMediaChange(): void {
    this.subscribeTo = this._fuseMediaWatcherService.onMediaChange$.subscribe({
      next: ({ matchingAliases }): void => {
        this.isScreenSmall = !matchingAliases.includes("md");
      },
    });
  }
}

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableProdMode } from "@angular/core";

import { AppModule } from "app/app.module";
import { environment } from "@env/environment";
import { defineCustomElements } from "xml-viewer-component/dist/loader";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

void defineCustomElements(window);

import { IPath } from "@core/services/common/path-tracker/path.interface";

export const PATHS_LIST: IPath[] = [
  {
    link: "/departments",
    title: "Breadcrumbs.Services",
    availableToNavigate: true,
  },

  //CARD ONBOARDING
  {
    link: "/card-on-boarding-front",
    title: "Breadcrumbs.Departments.OnboardingFront",
    availableToNavigate: true,
  },
  ////CLIENTS
  {
    link: "/card-on-boarding-front/clients",
    title: "Breadcrumbs.Clients.WorkWithClients",
    hasQuery: true,
    availableToNavigate: true,
  },
  {
    link: "/card-on-boarding-front/clients/create-physical",
    title: "Breadcrumbs.Clients.CardIndividual",
    availableToNavigate: true,
  },
  {
    link: "/card-on-boarding-front/clients/create-legal",
    title: "Breadcrumbs.Clients.CardLegalEntity",
    availableToNavigate: true,
  },
  {
    link: "/card-on-boarding-front/clients/create-entrepreneur",
    title: "Breadcrumbs.Clients.CardIndividualEntrepreneur",
    availableToNavigate: true,
  },
  {
    link: "/card-on-boarding-front/clients/something",
    title: "Breadcrumbs.Clients.ClientData",
    availableToNavigate: false,
    isDynamic: true,
  },

  //CASH
  {
    link: "/cash-front",
    title: "Breadcrumbs.Departments.CashFront",
    availableToNavigate: true,
  },
  ///CURRENCY EXCHANGE
  {
    link: "/cash-front/currency-exchange",
    title: "Breadcrumbs.CurrencyExchange.CurrencyExchange",
    availableToNavigate: true,
  },
  ////CASH REGISTER
  {
    link: "/cash-front/cash-register",
    title: "Breadcrumbs.CashRegister.PaymentForProviders",
    availableToNavigate: true,
  },
  {
    link: "/cash-front/cash-reporting",
    title: "Breadcrumbs.CashRegisterHistory.History",
    availableToNavigate: false,
  },

  //LOANS
  {
    link: "/loans-front",
    title: "Breadcrumbs.Loans.LoanProductsFront",
    availableToNavigate: true,
  },
  {
    link: "/loans-back",
    title: "Breadcrumbs.Loans.LoanProductsBack",
    availableToNavigate: true,
  },
  ////CAR LOAN
  {
    link: "/loans-front/car-loan",
    title: "Breadcrumbs.Loans.CarLoansFront",
    availableToNavigate: true,
  },
  {
    link: "/loans-back/car-loan",
    title: "Breadcrumbs.Loans.CarLoansBack",
    availableToNavigate: true,
  },
  {
    link: "/loans-front/car-loan/create",
    title: "Breadcrumbs.Creation",
    availableToNavigate: true,
  },
  // BACK. MICROLOANS
  {
    link: "/loans-back/microloans",
    title: "Breadcrumbs.Loans.Microloans",
    availableToNavigate: true,
  },
  // BACK. KATM individuals
  {
    link: "/loans-back/katm",
    title: "Breadcrumbs.Loans.Katm.Root",
    availableToNavigate: true,
  },
  {
    link: "/loans-back/katm/create",
    title: "Breadcrumbs.Loans.Katm.Create",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/katm/report",
    title: "Breadcrumbs.Loans.Katm.Report",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/katm/status",
    title: "Breadcrumbs.Loans.Katm.Status",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/katm/decline",
    title: "Breadcrumbs.Loans.Katm.Decline",
    availableToNavigate: false,
  },
  // BACK. KATM legal entities
  {
    link: "/loans-back/katm-legal",
    title: "Breadcrumbs.Loans.KatmLegal.Root",
    availableToNavigate: true,
  },
  {
    link: "/loans-back/katm-legal/create",
    title: "Breadcrumbs.Loans.KatmLegal.Create",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/katm-legal/report",
    title: "Breadcrumbs.Loans.KatmLegal.Report",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/katm-legal/reports",
    title: "Breadcrumbs.Loans.KatmLegal.Reports",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/katm-legal/status",
    title: "Breadcrumbs.Loans.KatmLegal.Status",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/katm-legal/decline",
    title: "Breadcrumbs.Loans.KatmLegal.Decline",
    availableToNavigate: false,
  },
  //BACK. NOTARY
  {
    link: "/loans-back/notary",
    title: "Breadcrumbs.Loans.Notary.Root",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/notary/ban-by-subject",
    title: "Breadcrumbs.Loans.Notary.BanBySubject",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/notary/ban-by-reg-number",
    title: "Breadcrumbs.Loans.Notary.BanByRegNumber",
    availableToNavigate: false,
  },
  {
    link: "/loans-back/notary/lifting-bans",
    title: "Breadcrumbs.Loans.Notary.LiftingBans",
    availableToNavigate: false,
  },
];

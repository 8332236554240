import { NgModule } from "@angular/core";
import { LayoutComponent } from "app/layout/layout.component";
import { EmptyLayoutModule } from "app/layout/layouts/empty/empty.module";
import { ClassicLayoutModule } from "app/layout/layouts/classic/classic.module";
import { SharedModule } from "app/shared/shared.module";
import { DenseLayoutModule } from "@layout/layouts/dense/dense.module";

const layoutModules = [EmptyLayoutModule, ClassicLayoutModule];

@NgModule({
  declarations: [LayoutComponent],
  imports: [SharedModule, ...layoutModules, DenseLayoutModule],
  exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule {}

<div>
  <app-scanner
    [isScanButtonDisabled]="isScanButtonDisabled"
    (resetSearching)="resetSearching()"
    (scanDocument)="scanDocument($event)"
    (scanningStarted)="scanningStarted()"
  ></app-scanner>
  <form class="card" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex items-center space-x-5 p-4">
      <div class="flex-auto w-2/6">
        <mat-form-field class="w-full">
          <mat-label> Номер телефона </mat-label>
          <span matPrefix>{{ phonePrefix }} &nbsp;</span>
          <input
            matInput
            [autocomplete]="'off'"
            [formControlName]="'username'"
            [readonly]="isUsernameReadonly"
          />
        </mat-form-field>
        <ng-container *ngIf="searchForm.controls.username as username">
          <div
            class="input-error -mt-3"
            *ngIf="username.dirty && username.hasError('pattern')"
          >
            Номер телефона неверный (должен состоять из 9 цифр)
          </div>
        </ng-container>
      </div>
      <div class="flex-auto w-2/6">
        <mat-form-field class="w-full">
          <mat-label>ПИНФЛ</mat-label>
          <input
            matInput
            maxlength="14"
            name="pinfl"
            [formControlName]="'pinfl'"
            [readonly]="isPINFLReadonly"
          />
          <mat-hint align="end">
            {{ searchForm.value.pinfl?.length ?? 0 }} / 14
          </mat-hint>
        </mat-form-field>
        <ng-container *ngIf="searchForm.controls.pinfl as pinfl">
          <div
            class="input-error -mt-3"
            *ngIf="pinfl.dirty && pinfl.hasError('pattern')"
          >
            ПИНФЛ неверный (должен состоять из 14 цифр)
          </div>
        </ng-container>
      </div>

      <div class="flex-auto w-1/6">
        <mat-form-field class="w-full">
          <mat-label>Серия документа</mat-label>
          <input
            matInput
            [autocomplete]="'off'"
            [formControlName]="'documentSerial'"
            [readonly]="isDocumentSerialReadonly"
          />
        </mat-form-field>
        <ng-container
          *ngIf="searchForm.get('documentSerial') as documentSerial"
        >
          <div
            class="input-error -mt-3"
            *ngIf="documentSerial.touched && documentSerial.hasError('pattern')"
          >
            Серия документа неверный (должен состоять из 2 букв в верхнем
            регистре)
          </div>
        </ng-container>
      </div>
      <div class="flex-auto w-1/6">
        <mat-form-field class="w-full">
          <mat-label>Номер документа</mat-label>
          <input
            matInput
            [autocomplete]="'off'"
            [formControlName]="'documentNumber'"
            [readonly]="isDocumentNumberReadonly"
          />
        </mat-form-field>
        <ng-container
          *ngIf="searchForm.get('documentNumber') as documentNumber"
        >
          <div
            class="input-error -mt-3"
            *ngIf="documentNumber.dirty && documentNumber.hasError('pattern')"
          >
            Номер документа неверный (должен состоять из 7 цифр)
          </div>
        </ng-container>
      </div>
      <button
        type="submit"
        mat-flat-button
        [color]="'primary'"
        subscriptSizing="dynamic"
        class="btn p-6"
        [disabled]="searchForm.invalid"
      >
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_outline:search'"
        ></mat-icon>
        <span class="ml-2">Найти</span>
      </button>
    </div>
  </form>

  <ng-container *ngIf="clientData$ | async as customerData">
    <app-clients-search-list
      (selectClientId)="onSelectedClientId($event)"
      [clientData]="customerData"
      [availableActionsWithClient]="availableActionsWithClient"
    />
  </ng-container>
</div>

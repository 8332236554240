import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ICard } from "@shared/interfaces/card.interface";
import { NavigationService } from "@core/navigation/navigation.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-cards",
  templateUrl: "./cards.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardsComponent {
  @Input() public cards: ICard[];

  public constructor(
    private _navigationService: NavigationService,
    private _router: Router
  ) {
    this._navigationService.setNavigationVisibility(false);
  }

  public navigate(route: string | undefined): void {
    if (route) void this._router.navigate([route]);
  }
}

export enum GenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum AddressEnum {
  PERMANENT = "PERMANENT",
  TEMPORARY = "TEMPORARY",
}

export enum ActionsEnum {
  More = "more",
  Choose = "choose",
}

import { ITableColumn } from "@shared/interfaces/table-columns.interface";

export const CLIENTS_SEARCH_LIST_COLUMNS: ITableColumn[] = [
  {
    name: "username",
    title: "Телефон",
  },
  {
    name: "fullName",
    title: "Фамилия",
  },
  {
    name: "address",
    title: "Адрес",
  },
  {
    name: "pinfl",
    title: "ПИНФЛ",
  },
  {
    name: "dob",
    title: "Дата рождения",
  },
];

export const CLIENTS_SEARCH_LIST_ACTION_COLUMN: ITableColumn = {
  name: "actions",
  title: "Действия",
};

<div class="flex justify-end mb-6">
  <button
    class="btn mr-2"
    mat-button
    [color]="'accent'"
    [disabled]="isResetButtonDisabled"
    (click)="onResetSearching()"
  >
    <mat-icon
      class="icon-size-5"
      [svgIcon]="'heroicons_solid:trash'"
    ></mat-icon>
  </button>
  <button
    class="btn px-6"
    mat-flat-button
    [color]="'accent'"
    [disabled]="isScanButtonDisabled"
    (click)="onScanDocument()"
  >
    <mat-icon
      class="icon-size-5"
      [svgIcon]="'mat_outline:document_scanner'"
    ></mat-icon>
    <span class="ml-2"> Сканировать документ </span>
  </button>
</div>
